@use "../../config/variables.scss" as vars;

.imagePicker-type2 {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px 10px;
  font-size: 15px;
  border: 2px dashed rgb(156, 156, 156);
  cursor: pointer;
  font-family: vars.$semibold;
  margin-top: 30px;
}
