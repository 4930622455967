* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* scroll-behavior: smooth; */
  -webkit-font-smoothing: antialiased;
  list-style: none;
}

::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "IsidoraSans-Bold";
  src: url("./assets/fonts/IsidoraSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "IsidoraSans-SemiBold";
  src: url("./assets/fonts/IsidoraSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "IsidoraSans-Light";
  src: url("./assets/fonts/IsidoraSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "IsidoraSans-Medium";
  src: url("./assets/fonts/IsidoraSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "IsidoraSans-Regular";
  src: url("./assets/fonts/IsidoraSans-Regular.ttf") format("truetype");
}