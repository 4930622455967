@use "../../config/variables.scss" as global;

.home {
  min-height: 100vh;
}

.main-body {
  min-height: 100vh;
  background-color: global.$grey1;
}

.home-box {
  height: 400px;
  width: 100%;
  background-image: url("../../assets/images/home-banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 767px) {
    height: 45vh;
  }
}

.home-card {
  .ant-card-body {
    @media (max-width: 767px) {
      padding: 15px;
    }
  }
  .ant-card-head {
    @media (max-width: 767px) {
      padding: 10px 15px;
    }
  }
}

.home-box2 {
  padding: 0px 40px;
  @media (max-width: 767px) {
    padding: 0px 0px;
  }
}

.home-text1 {
  color: #fff;
  font-size: 44px;
  font-family: global.$bold;
  position: absolute;
  top: 25vh;
  padding: 0px 40px;
  @media (max-width: 767px) {
    top: 24vh;
    font-size: 28px;
    padding: 0px 5px;
  }
}

.home-text2 {
  font-family: global.$medium;
  color: #000;
  font-size: 16px;
}

.home-text3 {
  font-family: global.$bold;
  color: #14151a;
  font-size: 20px;
  margin-bottom: 3px;
}

.home-input {
  padding: 12px 0px;
  font-family: global.$semibold;
  border-bottom: 1.5px solid rgb(15, 15, 15);
  .ant-input {
    font-family: global.$semibold;
    &::placeholder {
      color: #3f3f3f;
    }
  }
  &::placeholder {
    color: #3f3f3f;
  }
}

.home-select {
  font-family: global.$semibold;
  border-bottom: 1.5px solid rgb(15, 15, 15);
  .ant-select-selector {
    padding-left: -10px;
  }
  .ant-select-selection-placeholder {
    color: #3f3f3f;
    margin-left: -11px;
    margin-right: -11px;
    margin-bottom: 10px !important;
  }
  .ant-select-selection-search {
    margin-left: -11px;
    margin-right: -11px !important;
  }
  .ant-select-selection-item {
    margin-left: -11px;
    margin-right: -11px !important;
  }
  .ant-select-arrow {
    margin-right: -10px !important;
    color: rgb(15, 15, 15);
  }
}

.home-select2 {
  font-family: global.$semibold;
  padding: 0px !important;
  border-right: 1.5px solid rgb(232, 232, 232);
  .ant-select-selector {
  }
  .ant-select-selection-placeholder {
    color: #3f3f3f;
    margin-left: -11px;
    margin-right: -11px;
  }
  .ant-select-selection-search {
    margin-left: -11px;
  }
  .ant-select-selection-item {
    padding: 0px !important;
    text-align: left;
    margin-left: -12px !important;
  }
  .ant-select-arrow {
    color: rgb(15, 15, 15);
    font-size: 10px;
  }
}

.home-label {
  font-size: 13px;
  color: rgb(15, 15, 15);
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    font-size: 11px;
  }
}

.home-label2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.li {
  list-style-type: circle;
  display: inline;
  font-size: 14px;
}

.home-text {
  text-decoration: underline;
}

ul li {
  list-style-type: square;
  font-family: global.$medium;
}

// ---------- Success Modal ----------

.success-modal-box1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px;
}

.success-modal-txt1 {
  font-size: 25px;
  font-family: global.$semibold;
  color: #343434;
  text-align: center;
  padding-bottom: 10px;
}

.success-modal-txt2 {
  font-size: 16px;
  font-family: global.$medium;
  color: #343434;
  text-align: center;
  padding: 0px 25px;
}

.success-modal-btn {
  width: 200px;
  font-family: global.$semibold;
  color: #fff;
}

// ---------- Success Modal ----------

.sponsors-container {
  padding: 80px 50px 80px 50px;
  @media screen and (max-width: 768px) {
    padding: 40px 10px 40px 10px;
  }
}

.sponsers-title {
  font-family: global.$bold;
  font-size: 20px;
  margin-bottom: 30px;
}

.sponsors-input {
  font-family: global.$semibold;
  border-bottom: 1.5px solid rgb(15, 15, 15);
  .ant-input {
    font-family: global.$semibold;
    padding: 10px 0px !important;
    &::placeholder {
      color: #3f3f3f;
    }
  }
  &::placeholder {
    color: #3f3f3f;
  }
}

.sponsors-select2 {
  font-family: global.$semibold;
  border-bottom: 1.5px solid rgb(15, 15, 15);
  .ant-select-selector {
    padding-left: -10px;
  }
  .ant-select-selection-placeholder {
    color: #3f3f3f;
    margin-left: -11px;
    margin-right: -11px;
    margin-bottom: 10px !important;
    font-family: global.$semibold;
  }
  .ant-select-selection-search {
    margin-left: -11px;
    margin-right: -11px !important;
    font-family: global.$semibold;
  }
  .ant-select-selection-item {
    margin-left: -11px;
    margin-right: -11px !important;
    font-family: global.$semibold;
  }
  .ant-select-arrow {
    margin-right: -10px !important;
    color: rgb(15, 15, 15);
    font-family: global.$semibold;
  }
}
.sponsors-button {
  padding: 50px !important;
}

.sponsors-upolad-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  font-family: global.$regular;
  font-size: 12px;
}
.sponsor-FilePicker2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  border: 1px solid rgb(215, 215, 215);
  padding-right: 20px;
  font-family: global.$semibold;
  color: gray;
  margin-top: 10px;
  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
}
.sponsor-FilePicker-text {
  color: gray;
  font-family: global.$medium;
}
.sponsor-FilePicker-txt2 {
  color: black;
  font-family: global.$light;
  font-size: 14px;
  margin-top: 5px;
}
.sponsor-FilePicker {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.sponsors-label-row {
  margin-bottom: -20px;
  font-family: global.$medium;
  color: gray;
  font-size: 14px;
  a {
    font-family: global.$semibold;
    color: black;
    text-decoration: underline;
    font-size: 16px;
  }
}
.sponsors-view-log {
  margin-bottom: -25px;
  font-family: global.$medium;
  color: gray;
  font-size: 14px;
  a {
    font-family: global.$semibold;
    color: black;
    text-decoration: underline;
    font-size: 16px;
  }
}
.Sponsor-ticket-custom-table .ant-table-cell {
  width: 150px !important; /* Adjust according to your needs */
}
.sponsor-card {
  height: 90%;
}
.sponsor-text1 {
}

.sponsor-text2 {
}

.validate-modal-title {
  font-family: global.$semibold;
  font-size: 1.3vw;
  margin-bottom: 2vh;
  line-height: 1.4vw;
}

.sponsor-select2 {
  font-family: global.$medium;
  padding: 0px 0px 0px 0px !important;
  border-right: 1.5px solid rgb(55, 55, 55);
  margin-right: 10px !important;
  width: 120px;
  @media screen and (max-width: 768px) {
    width: 95px;
  }
  .ant-select-selector {
  }
  .ant-select-selection-placeholder {
    color: #3f3f3f;
    margin-left: -11px;
  }
  .ant-select-selection-search {
    margin-left: -11px;
  }
  .ant-select-selection-item {
    padding: 0px !important;
    text-align: left;
    margin-left: -12px !important;
  }
  .ant-select-arrow {
    color: rgb(15, 15, 15);
    font-size: 10px;
  }
}

.sponsers-success {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sponsers-success-title {
  font-family: global.$semibold;
  font-size: 30px;
}

.sponsers-success-subTitle {
  font-family: global.$medium;
  font-size: 20px;
}
.link-text {
  margin-left: 5px;
  margin-right: 5px;
}
