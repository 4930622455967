@use "../../config/variables.scss" as global;

.footer {
  background: #05172c;
  color: #fff;
  padding: 30px 0px;
}

.footer-box1 {
  flex: 1;
  width: 100%;
  padding: 0px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 25px;
  }
}

.footer-txt1 {
  font-size: 13px;
  font-family: global.$semibold;
  cursor: pointer;
  color: #fff;
  @media screen and (max-width: 720px) {
    font-size: 13px;
    text-align: center;
  }
}

.footer-txt2 {
  color: #fff;
  font-family: global.$semibold;
  font-size: 13px;
  margin-top: 5px;
  text-align: end;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 991px) {
    text-align: left;
  }
}
