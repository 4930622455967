@use "../../config/variables.scss" as vars;

.header {
  position: fixed;
  padding: 10px 20px;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: transparent;
  &.scrolled {
    background-color: vars.$dark;
    padding: 0px 5px;
  }
  @media (max-width: 767px) {
    padding: 0px 5px;
  }
}

.header-box {
  padding: 30px 40px;
  &.scrolled {
    padding: 15px 40px;
  }
  @media (max-width: 767px) {
    padding: 40px 0px;
    &.scrolled {
      padding: 20px 0px;
    }
  }
}

.header-logo {
  height: 90px;
  &.scrolled {
    height: 60px;
  }
  @media (max-width: 767px) {
    height: 55px;
    &.scrolled {
      height: 55px;
    }
  }
}
