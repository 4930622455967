// Fonts
$light: "IsidoraSans-Light";
$regular: "IsidoraSans-Regular";
$medium: "IsidoraSans-Medium";
$semibold: "IsidoraSans-SemiBold";
$bold: "IsidoraSans-Bold";

$primary: #1b6ad5;
$dark: #002646;
$grey1: #eee;
